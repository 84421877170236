import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Chip } from 'easyship-components'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AppBar from '@/components/AppBar'
import ItemsList from '@/components/ItemsList'
import Loading from '@/components/Loading'
import PhotoGuideLine from '@/components/PhotoGuideLine'
import Spacer from '@/components/Spacer'
import CustomStack from '@/components/Stack'
import ZendeskForm from '@/components/ZendeskForm'
import { useFeatureFlags } from '@/contexts/featureFlags'
import Package, { LABEL_STATE_TITLE } from '@/core/models/Package'
import { CourierReturnSectionWrapper } from '@/CourierReturn/CourierReturnSection'
import { MarkAsCourierReturn } from '@/CourierReturn/MarkAsCourierReturn'
import { CourierReturnLabelReason } from '@/CourierReturn/models/CourierReturn'
import {
  InspectionSource,
  ScreeningFlagCategory,
  WarehouseInspectionState,
  warehouseInspectionStates,
} from '@/gateways/api/models/Package'
import RepackSection from '@/pages/PackagePage/repack/Repack'

import { defaultSearchFormData, SearchFormData } from '../ScanPage'
import AffixBatteryStickers from './AffixBatteryStickers'
import BatteryInspectionOutcome from './BatteryInspectionOutcome'
import MeasureForm from './MeasureForm'
import PackageActions from './PackageActions'
import PackageDataList, { PackageDataItem } from './PackageDataList'
import PackageDropdownActions from './PackageDropdownActions'
import PackageInspection from './PackageInspection'
import PackageXRayInspection from './PackageInspection/PackageXRayInspection'
import { PackageProvider, usePackage } from './PackageProvider'
import PlaceInBin from './PlaceInBin/PlaceInBin'
import PlaceInBinDialog from './PlaceInBin/PlaceInBinDialog'

function PackageCourierReturnDataInfo() {
  const { pack } = usePackage()
  const courierReturnListData: PackageDataItem[] = [
    {
      label: 'Type of Courier Return',
      value: pack.courierReturn?.returnType,
    },
    {
      label: 'Reason for Return',
      value:
        pack.courierReturn?.returnReason &&
        CourierReturnLabelReason[pack.courierReturn?.returnReason],
    },
    {
      isVisible: pack.courierReturn?.contentDamaged || pack.courierReturn?.packagingDamaged,
      label: 'Type of Damage',
      value:
        pack.courierReturn?.contentDamaged && pack.courierReturn?.packagingDamaged
          ? 'Package Damage, Content Damage'
          : pack.courierReturn?.contentDamaged
          ? 'Content Damage'
          : pack.courierReturn?.packagingDamaged
          ? 'Package Damage'
          : '',
    },
  ]
  return <PackageDataList data={courierReturnListData} />
}

function PackageDataInfo() {
  const { pack } = usePackage()
  const isInspection = warehouseInspectionStates.includes(
    pack.warehouseState.value as WarehouseInspectionState,
  )
  const activeInspection = pack.inspections.find(
    (inspection) => inspection.status === 'categorized' || inspection.status === 'requested',
  )

  const isHVG = pack.warehouseState.value === 'hvg_inspection_required'

  const packageListData: PackageDataItem[] = [
    { label: 'Tracking #', value: pack.firstMileTrackingNumber },
    { label: 'Shipment ID', value: pack.easyshipShipmentId },
    { label: 'EVTN', value: pack.evtn },
    { label: 'Courier', value: pack.lastMileCourierName, canCopy: false },
    { label: 'Last Mile Tracking #', value: pack.lastMileTrackingNumber },
    {
      label: 'Items',
      value: <ItemsList items={pack.items} />,
      canCopy: false,
    },
    {
      label: 'Dimensions',
      value: `${valueOrEmpty(pack.dimensions.length)} x ${valueOrEmpty(
        pack.dimensions.width,
      )} x ${valueOrEmpty(pack.dimensions.height)} ${pack.dimensions.unit}`,
      canCopy: false,
    },
    {
      label: 'Weight',
      value: `${valueOrEmpty(pack.weight.value)} ${pack.weight.unit}`,
      canCopy: false,
    },
    {
      canCopy: false,
      label: 'Shipment Type',
      value: (
        <div>
          <Chip className="mr-1 text-base" color={pack.combinedShipment ? 'teal' : 'ink'}>
            {pack.combinedShipment ? 'Combined Shipping' : 'Regular Shipping'}
          </Chip>
          {pack.multipack && (
            <Chip className="text-base" color="blue">
              Multi-pack
            </Chip>
          )}
        </div>
      ),
    },
    {
      label: 'UPC',
      value: pack.repack?.upc || '-',
      canCopy: false,
    },
  ]
  if (isInspection) {
    packageListData.push({
      label: 'Tags',
      value: generateFlagChips(),
      canCopy: false,
    })
  }

  if (activeInspection || pack.packageTags?.length > 0) {
    packageListData.push({
      canCopy: false,
      label: 'Inspection Type',
      value: (
        <>
          {pack.packageTags?.map((tag, index) => (
            <Chip className={`text-base ${getInspectionTypeColor(tag.slug)} mb-1 mr-1`} key={index}>
              {tag.description}
            </Chip>
          ))}
          {activeInspection && (
            <Chip
              className={`text-base ${getInspectionTypeColor(activeInspection.source)} mb-1 mr-1`}
              key={activeInspection.source}
            >
              {getInspectionTypeLabel(activeInspection.source)}
            </Chip>
          )}
        </>
      ),
    })
  }

  function getInspectionTypeColor(source: InspectionSource) {
    const inspectionTypeColor = {
      random: 'bg-yellow-900',
      single_parcel_xray: 'bg-[#513b0b]',
      forced: 'bg-[#8a6310]',
      xray: 'bg-ink-300',
      courier_return: 'bg-[#a64a03]',
      snad: 'bg-[#c0865b]',
    }
    return inspectionTypeColor[source]
  }

  function getInspectionTypeLabel(source: InspectionSource) {
    const inspectionTypeColor = {
      random: 'Random (Open & Inspect)',
      single_parcel_xray: 'Single Parcel Xray',
      forced: 'Forced (Open & Inspect)',
      xray: 'Xray',
      courier_return: 'Courier Return',
      snad: 'SNAD',
    }
    return inspectionTypeColor[source]
  }

  function generateFlagChips() {
    const chips = moveHighValueToLast(pack.flaggedFor).map((flag) => (
      <Chip
        className="mb-1 mr-1 text-base"
        key={flag}
        color={flag === 'high_value' ? 'red' : 'yellow'}
      >
        {createChip(flag)}
      </Chip>
    ))
    if (
      (isHVG && pack.batteryInspectionOutcome.result === 'shippable') ||
      pack.batteryInspectionOutcome.result === 'shippable_with_stickers'
    ) {
      const batteryChip = createChip('battery')
      chips.unshift(batteryChip)
    }
    return chips
  }

  function valueOrEmpty(value: number): string {
    return value > 0 ? value.toString() : '-'
  }

  function createChip(flag: ScreeningFlagCategory) {
    return (
      <Chip className="mr-1 text-base" key={flag} color={flag === 'high_value' ? 'red' : 'yellow'}>
        {mapFlagToCode(flag)}
      </Chip>
    )
  }

  function mapFlagToCode(flag: ScreeningFlagCategory) {
    const flagToCode = {
      dangerous: 'Dangerous',
      prohibited: 'Prohibited',
      high_value: 'HVG',
      battery: 'Battery',
    }
    return flagToCode[flag]
  }

  // Always display HVG as the last element
  function moveHighValueToLast(flaggedFor: ScreeningFlagCategory[]) {
    const index = flaggedFor.indexOf('high_value')

    if (index !== -1) {
      flaggedFor.splice(index, 1)
      flaggedFor.push('high_value')
    }

    return flaggedFor
  }

  return <PackageDataList data={packageListData} />
}

function PackagePage() {
  const { isFeatureEnabled } = useFeatureFlags()
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams
  const {
    pack,
    bin,
    isLoading,
    loadingMessage,
    markAsReceived,
    measureWeightDimensions,
    submitZendeskTickets,
    forceDestroyLiquidate,
  } = usePackage()

  useEffect(() => {
    if (!pack) {
      navigate('/scan')
      return
    } else {
      history.replaceState({}, document.title)
    }
    if (pack.warehouseState.value === 'not_received') {
      markAsReceived(navigationState?.searchParams ?? defaultSearchFormData)
    }
  }, [pack])

  if (!pack) return null

  const hasBatteryInspectionOutcomeInstructions =
    pack.batteryInspectionOutcome.result !== 'no_batteries'

  const isCourierReturnState =
    pack.warehouseState.value === 'courier_returned' && !pack.courierReturn?.processed_at

  const isM3Enabled = isFeatureEnabled('EX2_795_M3_repacking')

  const isM4Enabled = isFeatureEnabled('EX2_795_M4_repacking')

  const canRepack =
    // Check if not in bin
    !bin &&
    // Check if warehouse state is one of: measured, or courier_returned
    ['measured', 'courier_returned'].includes(pack.warehouseState.value) &&
    // If state is courier_returned, ensure it has been processed and packaging is damaged but content is not damaged
    (pack.warehouseState.value !== 'courier_returned' ||
      (pack.courierReturn?.processed_at &&
        pack.courierReturn.packagingDamaged &&
        !pack.courierReturn.contentDamaged)) &&
    // Check if dimensions height is 0 when state is not courier_returned
    (pack.warehouseState.value === 'courier_returned' || pack.dimensions.height === 0) &&
    // Check if weight value exists
    !!pack.weight.value

  const showZendeskForm =
    pack.courierReturn?.returnReason === 'damaged' &&
    ((pack.warehouseState.value === 'label_failed' && pack.repack?.upc) ||
      (pack.warehouseState.value === 'courier_returned' && pack.courierReturn?.contentDamaged))

  return (
    <>
      <AppBar>
        <Button variant="text" onClick={() => navigate('/scan')}>
          back to scan
        </Button>
        <Spacer />
        <Button variant="text" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>
      <Container maxWidth="sm" sx={{ my: 2 }}>
        <CustomStack spacing={2} divider={<Divider light />}>
          <div>
            <Typography variant="h1" component="span">
              State:{' '}
            </Typography>
            <Typography variant="h2" component="span">
              {pack.warehouseState.displayName}{' '}
              {pack.warehouseState.value === 'label_failed' &&
                pack.labelState === 'no_rates' &&
                `(${LABEL_STATE_TITLE[pack.labelState]})`}
            </Typography>
          </div>
          <Stack direction="row">
            <Box flex={1}>
              <PackageDataInfo />
            </Box>
            <PackageDropdownActions />
          </Stack>
          {pack.courierReturn?.processed_at && (
            <Stack>
              <PackageCourierReturnDataInfo />
            </Stack>
          )}
          {!isLoading && <PackageInspection />}
          {!isLoading && <PackageXRayInspection />}
          {hasBatteryInspectionOutcomeInstructions && <BatteryInspectionOutcome />}
          {isLoading ? (
            <Loading text={loadingMessage} />
          ) : (
            <>
              {pack.batteryInspectionOutcome.stickers.length > 0 &&
                pack.warehouseState.value === 'battery_confirmed_compliant_with_stickers' && (
                  <AffixBatteryStickers stickers={pack.batteryInspectionOutcome.stickers} />
                )}
              <PlaceInBin />
              <PlaceInBinDialog />

              {pack.warehouseState.value === 'dropped' &&
                isFeatureEnabled('EX2_452_courier_returns') && <MarkAsCourierReturn />}
              {isFeatureEnabled('EX2_452_courier_returns') && isCourierReturnState && (
                <CourierReturnSectionWrapper />
              )}
              {pack.warehouseState.value === 'received' && (
                <MeasureForm onSubmit={measureWeightDimensions} />
              )}
              {(canRepack ||
                (isM3Enabled && pack.warehouseState.value === 'insufficient_packaging') ||
                (isM4Enabled && pack.warehouseState.value === 'received_damaged')) && (
                <RepackSection />
              )}

              <PackageActions />
              {showZendeskForm && (
                <>
                  <PhotoGuideLine Category="Damaged goods" />
                  <ZendeskForm
                    onZendeskCompleted={(zendeskTicketId) =>
                      submitZendeskTickets.mutate(zendeskTicketId, {
                        onSuccess: forceDestroyLiquidate,
                      })
                    }
                  />
                </>
              )}
            </>
          )}
        </CustomStack>
      </Container>
    </>
  )
}

export type NavigationStateParams =
  | Partial<{
      package: Package
      searchParams: SearchFormData
    }>
  | undefined

export default function PackagePageWrapper() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const navigationState = state as NavigationStateParams

  useEffect(() => {
    if (!navigationState?.package) {
      navigate('/scan')
    }
  }, [])

  if (!navigationState?.package) return null

  return (
    <PackageProvider initialPackage={navigationState.package}>
      <PackagePage />
    </PackageProvider>
  )
}
