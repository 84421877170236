import Link from '@mui/material/Link'
import { Button } from 'easyship-components'
import { ChevronDownward, ChevronUpward } from 'easyship-components/icons'
import { useState } from 'react'

import { PackageItem } from '@/core/models/Package'

import CopyToClipboard from './CopyToClipboard'

interface ItemsListProps {
  items: PackageItem[]
}

export default function ItemsList({ items }: ItemsListProps) {
  const [expanded, setExpanded] = useState(false)

  const handleClick = () => {
    setExpanded((prev) => !prev)
  }

  return (
    <div>
      <ol style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
        {items.slice(0, expanded ? items.length : 2).map((item) => (
          <li key={item.ebayItemId}>
            <div className="flex">
              <div className="mr-2">
                <Link className="inline" href={item.url} target="_blank" rel="noopener">
                  {item.description}
                </Link>{' '}
                x{item.quantity}
              </div>
              <CopyToClipboard value={`${item.description} x${item.quantity}`} />
            </div>
            <div className="flex items-center">
              <div className="mr-2">Item ID: {item.sku || '-'}</div>
              <CopyToClipboard value={item.sku} />
            </div>
          </li>
        ))}
      </ol>
      {items.length > 2 && (
        <Button
          color="primary"
          flat
          rightIcon={expanded ? <ChevronUpward /> : <ChevronDownward />}
          onClick={handleClick}
        >
          View {expanded ? 'Less' : 'More'}
        </Button>
      )}
    </div>
  )
}
