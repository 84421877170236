import Chance from 'chance'

import { FeatureFlags } from '@/gateways/api/models/FeatureFlags'

const chance = new Chance()

export const featureFlagsStub = (): FeatureFlags => ({
  EX_feature_1: chance.bool(),
  EX2_452_courier_returns: true,
  EX2_534_unshipped_courier_returns: true,
  EX2_795_M3_repacking: true,
  EX2_1371_extra_error_move_packages: true,
})
