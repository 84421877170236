import { Tooltip } from 'easyship-components'
import { Copy } from 'easyship-components/icons'
import { useState } from 'react'

interface CopyToClipboardProps {
  value?: string
}

export default function CopyToClipboard({ value }: CopyToClipboardProps) {
  const [copyClicked, setCopyClicked] = useState(false)

  function handleCopyValue(value: React.ReactNode) {
    navigator.clipboard.writeText(value as string)
    setCopyClicked(true)
  }

  return (
    <div>
      <Tooltip hideOnClick={false} label={copyClicked ? 'Copied' : 'Copy'}>
        <Copy
          onMouseOut={() => setCopyClicked(false)}
          className="cursor-pointer fill-ink-300"
          onClick={() => handleCopyValue(value)}
          width={16}
          height={16}
        />
      </Tooltip>
    </div>
  )
}
