import { Checkbox, FormGroup, FormLabel } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import { Button, Card, CardContent } from 'easyship-components'
import { useState } from 'react'

import Divider from '@/components/Divider'
import RadioGroup from '@/components/RadioGroup'
import { useFeatureFlags } from '@/contexts/featureFlags'
import Package from '@/core/models/Package'

import { SHIPPED_REASON_OPTIONS, UNSHIPPED_REASON_OPTIONS } from './CourierReturnForm.data'
import { CourierReturnReason } from './gateways/api/models/CourierReturn'

export interface CourierReturnEditFormData {
  returnType: 'shipped' | 'unshipped'
  returnReason: CourierReturnReason | null
  packageAction: 'to_be_destroyed' | 'to_be_liquidated' | null
  contentDamaged: boolean
  packagingDamaged: boolean
  labelUsable: boolean | null
}

export interface CourierReturnFormData {
  returnType: 'shipped' | 'unshipped'
  returnReason: CourierReturnReason
  packageAction: 'to_be_destroyed' | 'to_be_liquidated' | null
  contentDamaged: boolean
  packagingDamaged: boolean
  labelUsable: boolean | null
}

const defaultCourierReturnFormData: CourierReturnEditFormData = {
  returnType: 'shipped',
  returnReason: null,
  packageAction: null,
  contentDamaged: false,
  packagingDamaged: false,
  labelUsable: null,
}

export type NavigationStateParams = Partial<{ package: Package }> | undefined

interface CourierReturnFormProps {
  onSubmit: (formData: CourierReturnFormData) => Promise<void>
  isLoading?: boolean
  pack: Package
}

const DESTROY_BUTTON_VISIBLE_FOR_REASONS = [
  'import_not_authorized',
  'insufficient_address',
  'refused',
  'refused_by_customs',
  'unclaimed',
  'unknown',
]

const LIQUIDATE_BUTTON_VISIBLE_FOR_REASONS = [
  'denied_party',
  'import_not_authorized',
  'insufficient_address',
  'item_not_received',
  'refused',
  'refused_by_customs',
  'unclaimed',
  'unknown',
]

export function CourierReturnForm({ onSubmit, isLoading, pack }: CourierReturnFormProps) {
  const { isFeatureEnabled } = useFeatureFlags()
  const [formData, setFormData] = useState<CourierReturnEditFormData>({
    ...defaultCourierReturnFormData,
    ...pack.courierReturn,
  })

  const lastProcessedCourierReturnReason = pack.lastProcessedCourierReturn?.returnReason
  const courierReturnReasonOptions =
    formData.returnType === 'shipped' ? SHIPPED_REASON_OPTIONS : UNSHIPPED_REASON_OPTIONS
  const isDestroyButtonVisible =
    !!formData.returnReason &&
    (pack.processedCourierReturnCount >= 2 ||
      DESTROY_BUTTON_VISIBLE_FOR_REASONS.includes(formData.returnReason) ||
      (pack.processedCourierReturnCount >= 1 &&
        lastProcessedCourierReturnReason !== 'missorted' &&
        formData.returnReason !== 'missorted')) &&
    !(
      formData.returnReason === 'unknown' &&
      formData.returnType === 'unshipped' &&
      pack.processedCourierReturnCount === 0
    )
  const isLiquidateButtonVisible =
    !!formData.returnReason &&
    (pack.processedCourierReturnCount >= 2 ||
      LIQUIDATE_BUTTON_VISIBLE_FOR_REASONS.includes(formData.returnReason) ||
      (pack.processedCourierReturnCount >= 1 &&
        lastProcessedCourierReturnReason !== 'missorted' &&
        formData.returnReason !== 'missorted')) &&
    !(
      formData.returnReason === 'unknown' &&
      formData.returnType === 'unshipped' &&
      pack.processedCourierReturnCount === 0
    )
  const isFormDataValid = validateFormData(formData)

  function validateFormData(formData: CourierReturnEditFormData): boolean {
    if (formData.returnType === 'unshipped') {
      if (formData.returnReason === 'damaged') {
        return formData.contentDamaged || formData.packagingDamaged
      } else if (formData.returnReason === 'missorted') {
        return formData.labelUsable !== null
      } else if (
        formData.returnReason === 'item_not_received' ||
        formData.returnReason === 'denied_party'
      ) {
        return !!formData.packageAction
      } else {
        return !!formData.returnReason
      }
    } else {
      return !!formData.returnReason && !!formData.packageAction
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!validateFormData(formData)) return

    onSubmit(formData as CourierReturnFormData)
  }

  function updateFormData(updates: Partial<CourierReturnEditFormData>) {
    setFormData({ ...formData, ...updates })
  }

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <div className="flex gap-10">
        <Card
          onClick={() => {
            updateFormData({
              ...defaultCourierReturnFormData,
              returnType: 'shipped',
            })
          }}
          className={`${
            formData.returnType === 'shipped' ? 'selected' : ''
          }  w-[270px] text-center font-bold uppercase `}
          variant="normal"
          selected={formData.returnType === 'shipped'}
          key="shipped"
        >
          <CardContent className="text-ink-500">shipped</CardContent>
        </Card>

        <Card
          onClick={() => {
            updateFormData({
              ...defaultCourierReturnFormData,
              returnType: 'unshipped',
            })
          }}
          className={`${formData.returnType === 'unshipped' ? 'selected' : ''} ${
            !isFeatureEnabled('EX2_534_unshipped_courier_returns')
              ? 'pointer-events-none bg-sky-500 text-white'
              : 'text-ink-500'
          } w-[270px] text-center font-bold uppercase `}
          variant="normal"
          selected={formData.returnType === 'unshipped'}
          key="unshipped"
        >
          <CardContent
            className={
              !isFeatureEnabled('EX2_534_unshipped_courier_returns')
                ? ' text-white'
                : 'text-ink-500'
            }
          >
            unshipped
          </CardContent>
        </Card>
      </div>
      {formData.returnType && (
        <>
          <Divider />
          <RadioGroup
            label="Reason for Return"
            value={formData.returnReason}
            onChange={(e) =>
              updateFormData({
                returnReason: e.target.value as CourierReturnReason,
                packageAction: null,
                labelUsable: null,
                contentDamaged: false,
                packagingDamaged: false,
              })
            }
            className="!grid w-full grid-flow-row auto-rows-max grid-cols-2 gap-x-10"
          >
            {courierReturnReasonOptions.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={label}
                className="py-2"
              />
            ))}
          </RadioGroup>
        </>
      )}

      {formData.returnReason === 'damaged' && (
        <>
          <Divider />
          <FormLabel component="legend">Type of Damage</FormLabel>
          <FormGroup className="!grid w-full grid-flow-row auto-rows-max grid-cols-2 gap-x-10">
            <FormControlLabel
              checked={formData.packagingDamaged}
              control={
                <Checkbox
                  onChange={(e) => updateFormData({ packagingDamaged: e.target.checked })}
                />
              }
              label="Package Damage"
              name="packagingDamaged"
              className="py-2"
            />
            <FormControlLabel
              checked={formData.contentDamaged}
              control={
                <Checkbox onChange={(e) => updateFormData({ contentDamaged: e.target.checked })} />
              }
              label="Content Damage"
              name="contentDamaged"
              className="py-2"
            />
          </FormGroup>
        </>
      )}
      {formData.returnReason === 'missorted' && (
        <>
          <Divider />
          <RadioGroup
            label="Type of Missort"
            value={formData.labelUsable}
            onChange={(e) =>
              updateFormData({
                labelUsable: e.target.value === 'true' ? true : false,
              })
            }
            className="!grid w-full grid-flow-row auto-rows-max grid-cols-2 gap-x-10"
          >
            <FormControlLabel
              key="usable"
              value={true}
              control={<Radio />}
              label={'Label is usable'}
              className="py-2"
            />
            <FormControlLabel
              key="Unusable"
              value={false}
              control={<Radio />}
              label={'Label is not usable. Reprint needed'}
              className="py-2"
            />
          </RadioGroup>
        </>
      )}
      {!!formData.returnReason && (isDestroyButtonVisible || isLiquidateButtonVisible) && (
        <div className="flex gap-10 mt-7">
          {isDestroyButtonVisible && (
            <Card
              onClick={() => updateFormData({ packageAction: 'to_be_destroyed' })}
              className={`${
                formData.packageAction === 'to_be_destroyed' ? 'selected' : ''
              } w-[270px] text-center font-bold uppercase text-ink-500`}
              variant="normal"
              selected={formData.packageAction === 'to_be_destroyed'}
              key="to_be_destroyed"
            >
              <CardContent className="text-center">destroy</CardContent>
            </Card>
          )}
          {isLiquidateButtonVisible && (
            <Card
              onClick={() => updateFormData({ packageAction: 'to_be_liquidated' })}
              className={`${
                formData.packageAction === 'to_be_liquidated' ? 'selected' : ''
              } w-[270px] text-center font-bold uppercase text-ink-500`}
              variant="normal"
              selected={formData.packageAction === 'to_be_liquidated'}
              key="to_be_liquidated"
            >
              <CardContent>liquidate</CardContent>
            </Card>
          )}
        </div>
      )}
      <Divider />
      <Button
        loading={isLoading}
        type="submit"
        disabled={!isFormDataValid}
        className="h-[80px] w-[270px] self-center"
        color="primary"
      >
        Confirm
      </Button>
    </form>
  )
}
