import { Button } from 'easyship-components'
import { Prep } from 'easyship-components/icons'

import ScanInput from '@/components/ScanInput'
import { useFeatureFlags } from '@/contexts/featureFlags'
import { usePackage } from '@/pages/PackagePage/PackageProvider'

export default function RepackSection() {
  const { pack, requestRepack, skipRepack } = usePackage()
  const { isFeatureEnabled } = useFeatureFlags()

  const isM3Enabled = isFeatureEnabled('EX2_795_M3_repacking')
  const hideSkipButtonForM3 = isM3Enabled && pack.warehouseState.value === 'insufficient_packaging'

  const isM4Enabled = isFeatureEnabled('EX2_795_M4_repacking')
  const hideSkipForM4 = isM4Enabled && pack.warehouseState.value === 'received_damaged'

  async function handleScanChange(upc: string) {
    await requestRepack.mutate({ packageId: pack.id, upc })
  }

  function handleSkipRepack() {
    skipRepack.mutate()
  }

  return (
    <>
      <div
        className={`${
          pack.warehouseState.value === 'received_damaged' ? 'bg-red-100' : 'bg-blue-100'
        } mb-5 rounded px-5 py-2 pb-5`}
      >
        {pack.warehouseState.value === 'received_damaged' && (
          <div className="mb-3 flex items-center">
            <Prep className="mr-2" />
            <div className="text-xl font-bold text-ink-900">This package needs to be repacked</div>
          </div>
        )}
        <div className="mb-3">
          <div className="text-lg font-bold text-ink-900">
            If this package was repacked, scan the UPC barcode
          </div>
        </div>
        <ScanInput placeholder="Scan UPC" onChange={handleScanChange} autoFocus />
        {pack.warehouseState.value !== 'courier_returned' &&
          !hideSkipButtonForM3 &&
          !hideSkipForM4 && (
            <div className="mt-5">
              <Button className="h-20 w-full" onClick={handleSkipRepack}>
                Skip Repack
              </Button>
            </div>
          )}
      </div>
    </>
  )
}
