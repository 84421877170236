import { useMutation } from '@tanstack/react-query'

import { useGateways } from '@/contexts/gateways'

interface MovePackagesToContainerMutationParams {
  packageIds: string[]
  binId: number
}

export function useMovePackagesToContainerMutation() {
  const { moveItemsGateway } = useGateways()

  return useMutation({
    mutationFn: ({ packageIds, binId }: MovePackagesToContainerMutationParams) =>
      moveItemsGateway.movePackagesToContainer(packageIds, binId),
  })
}
