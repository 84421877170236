import { Button } from 'easyship-components'
import { Exclaim } from 'easyship-components/icons'
import { useState } from 'react'

import ScanInput from '@/components/ScanInput'
import { useFeatureFlags } from '@/contexts/featureFlags'
import { useCourierReturnInspectionMutation } from '@/CourierReturn/useCourierReturnInspectionMutation'

import { usePackage } from './PackageProvider'

interface ActionButton {
  label: string
  isVisible: boolean
  callback: () => void
  color: 'primary' | 'danger' | 'default' | undefined
  className?: string
  id?: string
}
interface MessageProps {
  message: string
}

function MessageWithExclaim({ message }: MessageProps) {
  return (
    <div className="mb-3 flex items-center">
      <Exclaim className="mr-2 fill-red-900" />
      <div className="text-xl font-bold text-ink-500">{message}</div>
    </div>
  )
}

export default function PackageActions() {
  const {
    pack,
    bin,
    markForDestruction,
    markForLiquidation,
    requestInspection,
    markAsReceivedDamaged,
    forceDestroyLiquidate,
  } = usePackage()
  const { mutate: mutateCourierReturnInspection, isLoading } = useCourierReturnInspectionMutation()
  const { isFeatureEnabled } = useFeatureFlags()
  const showMarkAsDamageForM4 =
    isFeatureEnabled('EX2_795_M4_repacking') && pack.canBeMarkedReceivedDamaged
  const [scannedCode, setScannedCode] = useState('')
  const [destroyLiquidateAction, setDestroyLiquidateAction] = useState<
    'destroy' | 'liquidate' | null
  >(null)

  if (bin) return null

  const isReadyForDestroyLiquidateOrLiquidateDPS =
    pack.warehouseState.value === 'ready_for_destroy_liquidate' ||
    pack.warehouseState.value === 'ready_for_liquidate_dps'
  const isReadyForDestroyLiquidate = pack.warehouseState.value === 'ready_for_destroy_liquidate'
  const isCourierReturned = pack.warehouseState.value === 'courier_returned'
  const hasBatteryRestriction = pack.courierReturn?.returnReason === 'battery_restriction'
  const hasDgPgRestriction = pack.courierReturn?.returnReason === 'dg_pg_restriction'

  const showLiquidateButton =
    ['ready_for_liquidate_dps', 'ready_for_destroy_liquidate'].includes(
      pack.warehouseState.value,
    ) && !pack.courierReturn?.contentDamaged

  const showToBeInspectedButton =
    isCourierReturned &&
    (hasBatteryRestriction || hasDgPgRestriction) &&
    !!pack.courierReturn?.processed_at

  const shouldShowReshopMessage =
    isReadyForDestroyLiquidate && pack.courierReturn?.label && !pack.courierReturn.label.active

  const shouldShowInspectionMessage =
    isCourierReturned &&
    pack.courierReturn?.processed_at &&
    (hasBatteryRestriction || hasDgPgRestriction)

  function handleScan(upc: string) {
    setScannedCode(upc)
  }
  function handleDestroyLiquidate() {
    if (destroyLiquidateAction === 'destroy') {
      markForDestruction(scannedCode)
    }
    if (destroyLiquidateAction === 'liquidate') {
      markForLiquidation(scannedCode)
    }
  }

  function getInspectionMessage() {
    if (hasBatteryRestriction) {
      return 'No Previous Battery Inspection Result'
    } else if (hasDgPgRestriction) {
      return 'No Previous DG/PG Inspection Result'
    }
    return ''
  }
  function handleMarkForLiquidation() {
    setDestroyLiquidateAction('liquidate')
  }
  function handleMarkForDestruction() {
    setDestroyLiquidateAction('destroy')
  }

  const actionButtons: ActionButton[] = [
    {
      label: 'request inspection',
      isVisible: ['ready_for_xray', 'xray_completed'].includes(pack.warehouseState.value),
      callback: () => requestInspection('xray'),
      color: 'default',
    },
    {
      label: 'destroy',
      isVisible: isReadyForDestroyLiquidate,
      callback: handleMarkForDestruction,
      color: 'default',
      className: destroyLiquidateAction === 'destroy' ? 'border border-teal-500' : '',
    },
    {
      label: 'liquidate',
      isVisible: showLiquidateButton,
      callback: handleMarkForLiquidation,
      color: 'default',
      className: destroyLiquidateAction === 'liquidate' ? 'border border-teal-500' : '',
    },
    {
      label: 'To Be Inspected',
      isVisible: showToBeInspectedButton,
      callback: () =>
        mutateCourierReturnInspection({
          packageId: pack.id,
          courierReturnId: pack.courierReturn?.id as string,
        }),
      color: 'default',
    },
    {
      label: 'mark as received damaged',
      isVisible: showMarkAsDamageForM4 && pack.warehouseState.value === 'measured',
      callback: () => markAsReceivedDamaged.mutate(),
      color: 'danger',
      id: 'mark_received_damaged',
    },
    {
      label: 'item(s) damaged, move to destroy/liquidate',
      isVisible: pack.warehouseState.value === 'received_damaged',
      callback: forceDestroyLiquidate,
      color: 'danger',
      id: 'move_to_destroy_liquidate',
    },
  ]

  const actionButtonsVisible = actionButtons.filter((actionButton) => actionButton.isVisible)

  if (actionButtonsVisible.length === 0) return null

  return (
    <>
      {shouldShowReshopMessage && <MessageWithExclaim message="Cannot Be Reshopped" />}
      {shouldShowInspectionMessage && <MessageWithExclaim message={getInspectionMessage()} />}
      {isReadyForDestroyLiquidateOrLiquidateDPS && (
        <ScanInput
          placeholder="Scan QR codes to enter comments"
          onChange={handleScan}
          autoFocus
          clearOnScan={false}
        />
      )}
      <div
        className={`flex flex-wrap gap-y-6 ${
          actionButtonsVisible.length === 1 ? 'justify-center' : 'justify-between'
        }`}
      >
        {actionButtonsVisible.map((actionButton) => (
          <div
            key={actionButton.label}
            className={
              actionButton.id === 'mark_received_damaged' ||
              actionButton.id === 'move_to_destroy_liquidate'
                ? 'w-full'
                : 'max-w-[48%] flex-[48%]'
            }
          >
            <Button
              onClick={actionButton.callback}
              color={actionButton.color}
              className={actionButton.className + ' h-[80px] w-full'}
              loading={actionButton.label === 'To Be Inspected' && isLoading}
            >
              {actionButton.label}
            </Button>
          </div>
        ))}
      </div>
      {isReadyForDestroyLiquidateOrLiquidateDPS && (
        <div className="flex justify-center">
          <Button
            disabled={!scannedCode || !destroyLiquidateAction}
            onClick={handleDestroyLiquidate}
            color="primary"
            className="h-[80px] w-[270px]"
          >
            Confirm
          </Button>
        </div>
      )}
    </>
  )
}
