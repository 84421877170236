import Package, { PackageMeasurements, PackageMeasurementsMetadata } from '@/core/models/Package'
import { Repack } from '@/core/models/Repack'
import PackagesGateway from '@/core/ports/packages'
import { MeasureFormData } from '@/pages/PackagePage/MeasureForm'
import { BatteryInspectionFormData } from '@/pages/PackagePage/PackageInspection/BatteryInspection'
import { SearchFormData } from '@/pages/ScanPage/ScanPage'
import ReturnPackage from '@/returnPackage/models/ReturnPackage'

import { ConfirmFlagPayload, DismissFlagPayload, InspectionSource } from '../api/models/Package'
import { packageStub } from './stubs/Package'
import { packageMeasurementsStub } from './stubs/PackageMeasurements'
import { repackStub } from './stubs/Repack'

const packages = [packageStub()]

function scan(barcode: string): Promise<(Package | ReturnPackage)[]> {
  return Promise.resolve(packages)
}

function markAsReceived(packageId: string, searchParams: SearchFormData): Promise<Package> {
  packages[0].warehouseState.value = 'received'
  return Promise.resolve({ ...packages[0] })
}

function measureWeightDimensions(
  packageId: string,
  formData: MeasureFormData,
  metadata: PackageMeasurementsMetadata | undefined,
): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function requestLabel(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function printLabel(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function requireInspection(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function requireDestroyLiquidate(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function dismissFlag(packageId: string, payload: DismissFlagPayload): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function confirmFlag(packageId: string, payload: ConfirmFlagPayload): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function submitBatteryInspection(
  packageId: string,
  formData: BatteryInspectionFormData,
): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function markForDestruction(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function markForLiquidation(packageId: string): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function printBarcode(packageId: string): Promise<void> {
  return Promise.resolve()
}

function reprintLabel(packageId: string): Promise<void> {
  return Promise.resolve()
}

function requestInspection(packageId: string, source: InspectionSource): Promise<Package> {
  return Promise.resolve({ ...packages[0] })
}

function placeInBin(packageId: string, barcode: string): Promise<void> {
  return Promise.resolve()
}

function getPackageMeasurements(): Promise<PackageMeasurements> {
  return Promise.resolve(packageMeasurementsStub())
}

function resetToMeasureStep(packageId: string): Promise<Package> {
  return Promise.resolve(packageStub())
}

function forceDestroyLiquidate(packageId: string): Promise<Package> {
  return Promise.resolve(packageStub())
}
function markAsCourierReturn(packageId: string): Promise<Package> {
  return Promise.resolve(packageStub())
}
function requestRepack(packageId: string, upc: string): Promise<Repack> {
  return Promise.resolve(repackStub())
}
function skipRepack(packageId: string): Promise<void> {
  return Promise.resolve()
}

function submitZendeskTickets(packageId: string, zendeskTicketId: string): Promise<void> {
  return Promise.resolve()
}

function getPackageById(packageId: string): Promise<Package> {
  return Promise.resolve(packages[0])
}

function markAsReceivedDamaged(packageId: string): Promise<void> {
  return Promise.resolve()
}

export default function createFakePackagesGateway(): PackagesGateway {
  return {
    scan,
    markAsReceived,
    measureWeightDimensions,
    requestLabel,
    printLabel,
    requireInspection,
    requireDestroyLiquidate,
    dismissFlag,
    confirmFlag,
    submitBatteryInspection,
    markForDestruction,
    markForLiquidation,
    printBarcode,
    reprintLabel,
    requestInspection,
    placeInBin,
    getPackageMeasurements,
    resetToMeasureStep,
    forceDestroyLiquidate,
    markAsCourierReturn,
    requestRepack,
    skipRepack,
    submitZendeskTickets,
    getPackageById,
    markAsReceivedDamaged,
  }
}
