import { Button } from 'easyship-components'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import AppBar from '@/components/AppBar'
import Loading from '@/components/Loading'
import Spacer from '@/components/Spacer'
import { toastApiError } from '@/components/Toastify'
import { useFeatureFlags } from '@/contexts/featureFlags'
import { ApiError } from '@/core/models/ApiError'

import CancelConfirmation from './components/CancelConfirmation'
import MoveActions from './components/MoveActions'
import MoveErrorCard from './components/MoveErrorCard'
import PackageMoveErrorCard from './components/PackageMoveErrorCard'
import RemoveConfirmation from './components/RemoveConfirmation'
import { BinScanAttributes, FloorAreaScanAttributes, ScanDetails } from './models/MoveItems'
import ScanContainer from './ScanContainer'
import { useMoveAllPackagesToContainerMutation } from './useMoveAllPackagesToContainerMutation'
import { useMoveContainerMutation } from './useMoveContainerMutation'
import { useMovePackagesToContainerMutation } from './useMovePackagesToContainerMutation'
import useScanDetailsQuery from './useScanDetailsQuery'

export default function MoveItems() {
  const navigate = useNavigate()
  const { isFeatureEnabled } = useFeatureFlags()

  const [barcode, setBarcode] = useState<{ value: string; time: number }>({ value: '', time: 0 })
  const scanDetailsQuery = useScanDetailsQuery(barcode.value)

  const [itemsScanned, setItemsScanned] = useState<ScanDetails[]>([])
  const [cancelOpenDialog, setCancelOpenDialog] = useState<boolean>(false)
  const [removeOpenDialog, setRemoveOpenDialog] = useState<boolean>(false)
  const [moveErrorOpenDialog, setMoveErrorOpenDialog] = useState<boolean>(false)
  const [packageMoveErrorDialog, setPackageMoveErrorDialog] = useState<boolean>(false)

  const movePackagesToContainerMutation = useMovePackagesToContainerMutation()
  const moveAllPackagesToContainerMutation = useMoveAllPackagesToContainerMutation()
  const moveContainerMutation = useMoveContainerMutation()

  const isLoadingMutation =
    movePackagesToContainerMutation.isLoading ||
    moveContainerMutation.isLoading ||
    moveAllPackagesToContainerMutation.isLoading

  const isContainerToContainer =
    itemsScanned.length > 1 && itemsScanned?.every((item) => item?.type === 'Bin')

  const packageCount =
    itemsScanned &&
    (itemsScanned[0]?.type === 'Package'
      ? itemsScanned.filter((item) => item.type === 'Package').length
      : (itemsScanned[0]?.attributes as BinScanAttributes)?.packagesCount)

  const handleCancelMove = () => {
    navigator.vibrate && navigator.vibrate(1000)
    setCancelOpenDialog(true)
  }

  function handleRemoveDuplicatePack() {
    setItemsScanned((prev) =>
      prev
        ? prev.filter((pack) => pack.attributes.id !== scanDetailsQuery.data?.attributes.id)
        : [],
    )
    setRemoveOpenDialog(false)
  }

  function isScanValid() {
    if (itemsScanned[0]?.type === 'Package' && scanDetailsQuery.data?.type === 'FloorArea') {
      return false
    }
    if (itemsScanned[0]?.type === 'Bin' && scanDetailsQuery.data?.type === 'Package') {
      return false
    }
    if (
      itemsScanned[0]?.type === 'Bin' &&
      itemsScanned[1]?.type === 'Bin' &&
      (scanDetailsQuery.data?.type === 'Package' ||
        scanDetailsQuery.data?.type === 'FloorArea' ||
        scanDetailsQuery.data?.type === 'Bin')
    ) {
      return false
    }
    if (
      itemsScanned[0]?.type === 'FloorArea' ||
      (itemsScanned.length === 0 && scanDetailsQuery.data?.type === 'FloorArea')
    ) {
      return false
    }
    return true
  }

  // Move all packages to the container
  function handleMoveAllPackages() {
    if (
      itemsScanned &&
      itemsScanned.length > 1 &&
      itemsScanned.slice(0, -1).every((item) => item?.type === 'Bin')
    ) {
      moveAllPackagesToContainerMutation.mutate(
        {
          sourceBinId: itemsScanned[0]?.attributes.id as number,
          destinationBinId: scanDetailsQuery.data?.attributes.id as number,
        },
        {
          onSuccess: () => {
            toast.success(
              `${packageCount} packages moved to courier container ${
                (scanDetailsQuery.data?.attributes as BinScanAttributes).name
              }`,
            )
            setItemsScanned([])
            setBarcode({ value: '', time: 0 })
          },
        },
      )
    }
  }

  // Add the scanned item to the list or remove it
  useEffect(() => {
    if (scanDetailsQuery.data) {
      if (isScanValid()) {
        if (
          itemsScanned?.some((item) => item.attributes.id === scanDetailsQuery.data.attributes.id)
        ) {
          // If the scanned item is already in the list, remove it
          navigator.vibrate && navigator.vibrate(1000)
          setRemoveOpenDialog(true)
        } else {
          // Add the scanned item to the list
          setItemsScanned((prev) =>
            prev ? [...prev.filter(Boolean), scanDetailsQuery.data] : [scanDetailsQuery.data],
          )
        }
      } else {
        setMoveErrorOpenDialog(true)
      }
    }
  }, [barcode, scanDetailsQuery.data])

  // Move the packages to the bin or the bin to the floor area
  useEffect(() => {
    if (scanDetailsQuery.data) {
      if (
        itemsScanned.length > 1 &&
        scanDetailsQuery.data?.type === 'Bin' &&
        itemsScanned.slice(0, -1).every((item) => item?.type === 'Package')
      ) {
        // If the scanned item is a bin and all the items in the list are packages, move the packages to the bin
        movePackagesToContainerMutation.mutate(
          {
            packageIds: itemsScanned.slice(0, -1).map((item) => item.attributes.id) as string[],
            binId: scanDetailsQuery.data.attributes.id as number,
          },
          {
            onSuccess: () => {
              toast.success(
                `${packageCount} packages moved to courier container ${
                  (scanDetailsQuery.data.attributes as BinScanAttributes).name
                }`,
              )
              setItemsScanned([])
              setBarcode({ value: '', time: 0 })
            },
            onError: (reason) => {
              if (
                isFeatureEnabled('EX2_1371_extra_error_move_packages') &&
                ((reason as ApiError)?.error?.detail[0] === 'Incorrect courier' ||
                  (reason as ApiError)?.error?.detail[0] === 'Container has no location')
              ) {
                setPackageMoveErrorDialog(true)
              } else {
                toastApiError(reason)
              }
            },
          },
        )
      }

      // If the scanned item is a floor area and all the items in the list are bins, move the bin to the floor area
      if (
        itemsScanned.length > 1 &&
        scanDetailsQuery.data?.type === 'FloorArea' &&
        itemsScanned[0].type === 'Bin'
      ) {
        moveContainerMutation.mutate(
          {
            sourceBinId: itemsScanned[0].attributes.id as number,
            floorAreaId: scanDetailsQuery.data.attributes.id as number,
          },
          {
            onSuccess: () => {
              toast.success(
                `Courier container ${
                  (itemsScanned[0].attributes as BinScanAttributes).name
                } moved to  ${(scanDetailsQuery.data.attributes as FloorAreaScanAttributes).name}`,
              )
              setItemsScanned([])
              setBarcode({ value: '', time: 0 })
            },
          },
        )
      }
    }
  }, [itemsScanned])

  return (
    <>
      <AppBar title="Move Packages">
        <Spacer />
        <Button flat color="primary" onClick={() => navigate('/')}>
          home
        </Button>
      </AppBar>

      <div className="relative flex h-[calc(100%-52px)] bg-sky-300 ">
        {isLoadingMutation && (
          <div className="flex w-full justify-center">
            <Loading text="Processing the move" />
          </div>
        )}
        {!isLoadingMutation && !cancelOpenDialog && !removeOpenDialog && !moveErrorOpenDialog && (
          <ScanContainer
            isFetching={scanDetailsQuery?.isFetching}
            itemsScanned={itemsScanned}
            onScannedBarcode={(barcode) => setBarcode(barcode)}
          />
        )}
        {itemsScanned.length > 0 && (
          <MoveActions
            isContainerToContainer={isContainerToContainer}
            onHandleCancelMove={handleCancelMove}
            onHandleMoveAllPackages={handleMoveAllPackages}
            packageCount={packageCount}
          />
        )}
      </div>

      <CancelConfirmation
        open={cancelOpenDialog}
        onCancel={() => {
          setItemsScanned([])
          setCancelOpenDialog(false)
        }}
        onDismiss={() => setCancelOpenDialog(false)}
      />
      <RemoveConfirmation
        scannedItem={scanDetailsQuery.data as ScanDetails}
        open={removeOpenDialog}
        onRemove={handleRemoveDuplicatePack}
        onDismiss={() => setRemoveOpenDialog(false)}
      />
      <MoveErrorCard
        open={moveErrorOpenDialog}
        onConfirm={() => setMoveErrorOpenDialog(false)}
        barcode={barcode.value}
      />
      {isFeatureEnabled('EX2_1371_extra_error_move_packages') && (
        <PackageMoveErrorCard
          open={packageMoveErrorDialog}
          onConfirm={() => setPackageMoveErrorDialog(false)}
          error={movePackagesToContainerMutation.error as ApiError}
        />
      )}
    </>
  )
}
