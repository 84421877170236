import { Dialog, DialogContent } from '@mui/material'
import { Button } from 'easyship-components'
import { Exclaim, NotSupported } from 'easyship-components/icons'

import { ApiError } from '@/core/models/ApiError'

interface CancelConfirmationProps {
  open: boolean
  onConfirm: () => void
  error?: ApiError
}

export default function PackageMoveErrorCard({ open, onConfirm, error }: CancelConfirmationProps) {
  const isIncorrectCourier = error?.error?.detail[0] === 'Incorrect courier'
  return (
    <Dialog open={open} fullScreen>
      <DialogContent className="flex flex-col items-center justify-center bg-red-700">
        {isIncorrectCourier ? (
          <NotSupported width="72px" height="72px" className="mb-1 fill-white" />
        ) : (
          <Exclaim width="72px" height="72px" className="mb-1 fill-white" />
        )}
        <div className="mb-5 text-center text-2xl font-bold text-white">
          {error?.error?.detail[0]}
        </div>
        {error?.error?.detail[1] && isIncorrectCourier && (
          <div className="mb-5 text-center text-lg font-bold text-white">
            {error?.error?.detail[1]}
          </div>
        )}
        <div className="mb-5 text-center text-lg text-white">
          {isIncorrectCourier
            ? `One or more package(s) have a label that does not match the courier container.
    Do not move the package(s). Place it/them back in their original location, or
    in a container matching the courier on the label.`
            : `This container has not been scanned to a location yet.
    Do not move the package(s). Please scan the container to a location before scanning
    the package(s) again. If you need assistance, notify your manager.`}
        </div>
        <div className="mt-5 flex">
          <Button onClick={onConfirm} className="w-full">
            confirm package not moved
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
